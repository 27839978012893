import { Component } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { LoadingService } from "./services/loading.service";
import { EventsService } from "./services/events.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  loading$ = this.loader.loading$;
  showContent: boolean = false;
  constructor(
    private router: Router,
    private auth: AuthService,
    public loader: LoadingService,
    public events: EventsService
  ) {}

  activeMenuItem: string | null = "";
  showDashboardSubmenu: boolean = false;
  showReportSubmenu: boolean = false;
  showPowerBISubmenu: boolean = false;

  currentUser: any = this.auth.getCurrentUser();

  ngOnInit() {
    console.log("AppComponent initialized"); // Debugging log
    const currentUser = this.auth.getCurrentUser();
    const currentPath = window.location.pathname;

    if (currentUser && currentPath === "/") {
      this.router.navigate(["/dashboard"]);
    } else if (currentPath === "/") {
      this.router.navigate(["/login"]);
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showContent = false;
      }
    });

    this.events.pageLoaded.subscribe(() => {
      this.showContent = true; // Show content when iYa-bot page is loaded
    });

    let menuItem: string | null = this.auth.getActiveMenuItem();
    if (menuItem) {
      this.auth.setActiveMenuItem(menuItem);
    } else {
      if (this.currentUser && this.currentUser.user.role.name !== "SUPERUSER") {
        this.auth.setActiveMenuItem("dashboard");
      } else {
        this.auth.setActiveMenuItem("coordinatorDashboard");
      }
    }
    this.activeMenuItem = this.auth.getActiveMenuItem();
  }

  showDashboard() {
    if (this.currentUser && this.currentUser.user.role.name !== "SUPERUSER") {
      this.activeMenuItem = "dashboard";
      this.auth.setActiveMenuItem("dashboard");
      this.router.navigateByUrl("/dashboard", { replaceUrl: true });
    } else {
      this.showDashboardSubmenu = !this.showDashboardSubmenu;
    }
  }

  coordinatorDashboard() {
    this.activeMenuItem = "coordinatorDashboard";
    this.auth.setActiveMenuItem("coordinatorDashboard");

    if (this.router.url !== "/dashboard") {
      this.router.navigateByUrl("/dashboard", { replaceUrl: true });
    } else if (this.router.url === "/dashboard") {
      this.reloadDashboard();
    }
  }

  auditorDashboard() {
    this.activeMenuItem = "auditorDashboard";
    this.auth.setActiveMenuItem("auditorDashboard");

    if (this.router.url !== "/dashboard") {
      this.router.navigateByUrl("/dashboard", { replaceUrl: true });
    } else {
      this.reloadDashboard();
    }
  }

  adjusterDashboard() {
    this.activeMenuItem = "adjusterDashboard";
    this.auth.setActiveMenuItem("adjusterDashboard");

    if (this.router.url !== "/dashboard") {
      this.router.navigateByUrl("/dashboard", { replaceUrl: true });
    } else {
      this.reloadDashboard();
    }
  }

  incomingShifts() {
    this.activeMenuItem = "incomingShifts";
    this.auth.setActiveMenuItem("incomingShifts");
    this.events.publishActiveMenuItem("incomingShifts");

    this.router.navigateByUrl("/incoming-shifts", { replaceUrl: true });
  }

  private reloadDashboard() {
    this.router.navigateByUrl("/dashboard-refresh", { replaceUrl: true });
  }

  redEntries() {
    this.activeMenuItem = "redEntries";
    this.auth.setActiveMenuItem("redEntries");
  }

  allEntries() {
    this.activeMenuItem = "allEntries";
    this.auth.setActiveMenuItem("allEntries");
  }

  reports() {
    // this.activeMenuItem = "reports";
    // this.auth.setActiveMenuItem("reports");

    this.showReportSubmenu = !this.showReportSubmenu;
  }

  reportByCategory() {
    this.activeMenuItem = "reportByCategory";
    this.auth.setActiveMenuItem("reportByCategory");
    this.events.publishActiveMenuItem("reportByCategory");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportByLocation() {
    this.activeMenuItem = "reportByLocation";
    this.auth.setActiveMenuItem("reportByLocation");
    this.events.publishActiveMenuItem("reportByLocation");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportByEmployee() {
    this.activeMenuItem = "reportByEmployee";
    this.auth.setActiveMenuItem("reportByEmployee");
    this.events.publishActiveMenuItem("reportByEmployee");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportAllTimeEntries() {
    this.activeMenuItem = "reportAllTimeEntries";
    this.auth.setActiveMenuItem("reportAllTimeEntries");
    this.events.publishActiveMenuItem("reportAllTimeEntries");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportForCoordinator() {
    this.activeMenuItem = "reportForCoordinator";
    this.auth.setActiveMenuItem("reportForCoordinator");
    this.events.publishActiveMenuItem("reportForCoordinator");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportSBTC() {
    this.activeMenuItem = "reportSBTC";
    this.auth.setActiveMenuItem("reportSBTC");
    this.events.publishActiveMenuItem("reportSBTC");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportCarraway() {
    this.activeMenuItem = "reportCarraway";
    this.auth.setActiveMenuItem("reportCarraway");
    this.events.publishActiveMenuItem("reportCarraway");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportAnomaly() {
    this.activeMenuItem = "reportAnomaly";
    this.auth.setActiveMenuItem("reportAnomaly");
    this.events.publishActiveMenuItem("reportAnomaly");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  payrollReport() {
    this.activeMenuItem = "payrollReport";
    this.auth.setActiveMenuItem("payrollReport");
    this.events.publishActiveMenuItem("payrollReport");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  reportIyaEmployeeEngagement() {
    this.activeMenuItem = "reportIyaEmployeeEngagement";
    this.auth.setActiveMenuItem("reportIyaEmployeeEngagement");
    this.events.publishActiveMenuItem("reportIyaEmployeeEngagement");

    this.router.navigateByUrl("/reports", { replaceUrl: true });
  }

  powerbi() {
    this.showPowerBISubmenu = !this.showPowerBISubmenu;
  }

  punchBuddyDashboard() {
    this.activeMenuItem = "punchBuddyDashboard";
    this.auth.setActiveMenuItem("punchBuddyDashboard");
    this.router.navigateByUrl("/punch-buddy-dashboard", { replaceUrl: true });
  }

  summaryDashboard() {
    this.activeMenuItem = "summaryDashboard";
    this.auth.setActiveMenuItem("summaryDashboard");
    this.router.navigateByUrl("/summary-dashboard", { replaceUrl: true });
  }

  shiftView() {
    this.activeMenuItem = "shiftView";
    this.auth.setActiveMenuItem("shiftView");
    this.router.navigateByUrl("/shift-view", { replaceUrl: true });
  }

  userManager() {
    this.activeMenuItem = "userManager";
    this.auth.setActiveMenuItem("userManager");
    this.router.navigateByUrl("/user-manager", { replaceUrl: true });
  }

  roleManager() {
    this.activeMenuItem = "roleManager";
    this.auth.setActiveMenuItem("roleManager");
    this.router.navigateByUrl("/role-manager", { replaceUrl: true });
  }

  clientTime() {
    this.activeMenuItem = "clientTime";
    this.auth.setActiveMenuItem("clientTime");
    this.router.navigateByUrl("/client-time-manager", { replaceUrl: true });
  }

  logout() {
    this.auth.resetAll();
    this.router.navigateByUrl("/login", { replaceUrl: true });
  }
}
