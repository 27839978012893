import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "user-manager",
    loadChildren: () =>
      import("./pages/user-manager/user-manager.module").then(
        (m) => m.UserManagerPageModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "dashboard-refresh",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "role-manager",
    loadChildren: () =>
      import("./pages/role-manager/role-manager.module").then(
        (m) => m.RoleManagerPageModule
      ),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/reports/reports.module").then((m) => m.ReportsPageModule),
  },
  {
    path: "shift-view",
    loadChildren: () =>
      import("./pages/shift-view/shift-view.module").then(
        (m) => m.ShiftViewPageModule
      ),
  },
  {
    path: "summary-dashboard",
    loadChildren: () =>
      import("./pages/summary-dashboard/summary-dashboard.module").then(
        (m) => m.SummaryDashboardPageModule
      ),
  },
  {
    path: "punch-buddy-dashboard",
    loadChildren: () =>
      import("./pages/punch-buddy-dashboard/punch-buddy-dashboard.module").then(
        (m) => m.PunchBuddyDashboardModule
      ),
  },
  {
    path: "client-time-manager",
    loadChildren: () =>
      import("./pages/client-time-manager/client-time-manager.module").then(
        (m) => m.ClientTimeManagerPageModule
      ),
  },
  {
    path: "incoming-shifts",
    loadChildren: () =>
      import("./pages/incoming-shifts/incoming-shifts.module").then(
        (m) => m.IncomingShiftsPageModule
      ),
  },
  // {
  //   path: "conversations/2minsAfterLate",
  //   loadChildren: () =>
  //     import("./pages/conversation/2minsAfterLate/late.module").then(
  //       (m) => m.LatePageModule
  //     ),
  // },
  // {
  //   path: "conversations/clockInAfterPromise",
  //   loadChildren: () =>
  //     import(
  //       "./pages/conversation/clock-in-after-promise/clock-in-after-promise.module"
  //     ).then((m) => m.ClockInAfterPromisePageModule),
  // },
  // {
  //   path: "conversations/whyWasItInaccurate",
  //   loadChildren: () =>
  //     import(
  //       "./pages/conversation/why-was-it-inaccurate/why-was-it-inaccurate.module"
  //     ).then((m) => m.WhyWasItInaccuratePageModule),
  // },
  // {
  //   path: "conversations/whatTypeOfTechFailure",
  //   loadChildren: () =>
  //     import(
  //       "./pages/conversation/what-type-of-tech-failure/what-type-of-tech-failure.module"
  //     ).then((m) => m.WhatTypeOfTechFailurePageModule),
  // },
  // {
  //   path: "conversations/whatTimeDidYouArrivedOnSite",
  //   loadChildren: () =>
  //     import(
  //       "./pages/conversation/what-time-did-you-arrived-on-site/what-time-did-you-arrived-on-site.module"
  //     ).then((m) => m.WhatTimeDidYouArrivedOnSitePageModule),
  // },
  // {
  //   path: "conversations/youHaveIssuesClockingIn",
  //   loadChildren: () =>
  //     import(
  //       "./pages/conversation/you-have-issues-clocking-in/you-have-issues-clocking-in.module"
  //     ).then((m) => m.YouHaveIssuesClockingInPageModule),
  // },
  {
    path: "i-ya-bot",
    loadChildren: () =>
      import("./pages/i-ya-bot/i-ya-bot.module").then(
        (m) => m.IYaBotPageModule
      ),
  },
  {
    path: "entry-details/:id/:source",
    loadChildren: () =>
      import("./pages/entry-details/entry-details.module").then(
        (m) => m.EntryDetailsPageModule
      ),
  },
  {
    path: "punch-buddy",
    loadChildren: () =>
      import("./pages/punch-buddy/punch-buddy.module").then(
        (m) => m.PunchBuddyPageModule
      ),
  },
  {
    path: "pb-beacon",
    loadChildren: () =>
      import("./pages/pb-beacon/pb-beacon.module").then(
        (m) => m.PbBeaconPageModule
      ),
  },
  {
    path: "iya-engagement-report",
    loadChildren: () =>
      import("./pages/iya-engagement-report/iya-engagement-report.module").then(
        (m) => m.IyaEngagementReportPageModule
      ),
  },
  {
    path: "quad-report",
    loadChildren: () =>
      import("./pages/quad-report/quad-report.module").then(
        (m) => m.QuadReportPageModule
      ),
  },
  {
    path: 'charge-report',
    loadChildren: () => import('./pages/charge-report/charge-report.module').then( m => m.ChargeReportPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
