import { Injectable } from "@angular/core";
import { LoginResponse } from "../domains/login-response";
import { CcpApi } from "../providers/api/ccp-api";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(public api: CcpApi) {}

  validateLoginResponse(response: LoginResponse) {
    let returnVal = false;
    // now we dont check auth token here
    if (response && response.user) {
      returnVal = true;
    }
    return returnVal;
  }

  getCurrentUser(): any {
    if (typeof Storage !== "undefined") {
      const userString: any = localStorage.getItem("user");
      const user = JSON.parse(userString);
      return user;
    } else {
      return "getCurrentUser error";
    }
  }

  //   getAuthorizationToken(): string {
  //     // Check if localStorage is available in the browser
  //     if (typeof Storage !== "undefined") {
  //       // Retrieve the user object from localStorage
  //       const userString: any = localStorage.getItem("user");

  //       // Parse the user object string to a JavaScript object
  //       const user = JSON.parse(userString);

  //       // Check if the user object and authToken exist
  //       if (user && user.authToken) {
  //         const authToken = user.authToken;
  //         // console.log("authToken:", authToken);
  //         return authToken;
  //         // You can now use the authToken as needed
  //       } else {
  //         console.log("authToken not found in local storage");
  //         return "";
  //       }
  //     } else {
  //       console.log("localStorage is not available in this browser");
  //       return "";
  //     }
  //   }

  resetAll() {
    localStorage.removeItem("user");
    localStorage.removeItem("cpp_active_menu_item");
    localStorage.removeItem("wcp_selected_accounts");
    localStorage.removeItem("wcp_selected_dashboard_date");
  }

  setActiveMenuItem(itemNumber: string): void {
    localStorage.setItem("cpp_active_menu_item", itemNumber);
  }

  getActiveMenuItem(): string | null {
    return localStorage.getItem("cpp_active_menu_item");
  }
}
