import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { finalize, Observable } from "rxjs";
import { LoadingService } from "../../services/loading.service";

@Injectable()
export class LoadingInterceptorService implements HttpInterceptor {
  totalRequests = 0;
  completedRequests = 0;
  routesToSkip = [
    "/system/getLastCronExecutionTimestamp",
    "/parimApi/checkingForNewRecords/",
    "/iYa/",
    "/parimApi/getEmployeeManagers",
    "/errors",
    // "/iYa/log",
    // "/iYa/updateClockInPromise",
    // "/iYa/setTAoutcome",
    // "/iYa/saveGeolocation",
    // "/iYa/canNotMakeShift",
  ];

  constructor(private loader: LoadingService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.routesToSkip.some((v) => request.url.includes(v))) {
      // There's at least one

      this.loader.show();
      this.totalRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.completedRequests++;
          //console.log(this.totalRequests, this.completedRequests);
          if (this.completedRequests === this.totalRequests) {
            this.loader.hide();
            this.completedRequests = 0;
            this.totalRequests = 0;
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
