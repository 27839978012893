import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { RouteReuseStrategy, RouterModule } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ButtonModule } from "primeng/button";

import { HttpErrorInterceptorService } from "./services/http_interceptors/http-error.interceptor.service";
import { AuthInterceptorService } from "./services/http_interceptors/auth.interceptor.service";
import { LoadingInterceptorService } from "./services/http_interceptors/loading.interceptor.service";

import { AppComponent } from "./app.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// function routes(
//   routes: any,
//   arg1: { onSameUrlNavigation: "reload" }
// ):
//   | any[]
//   | import("@angular/core").Type<any>
//   | import("@angular/core").ModuleWithProviders<{}> {
//   throw new Error("Function not implemented.");
// }
