import { EventEmitter, Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  public pageLoaded = new EventEmitter<void>();
  private subject = new Subject<any>();

  constructor() {}

  publishActiveMenuItem(data: any) {
    this.subject.next(data);
  }

  getActiveMenuItem(): Subject<any> {
    return this.subject;
  }
}
