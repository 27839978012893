export const environment = {
  production: false,
  api: {
    server: "https://devapi.workforcecentreplus.com",
    wss: "wss://devapi.workforcecentreplus.com",
    endpoint: "",
    maxWaiting: 10000,
    authHeader: "Authorization",
  },
  mapbox: {
    accessToken:
      "pk.eyJ1IjoidHdlZ3JvYjIwNTAiLCJhIjoiY20yZGUxNWp4MHBzMjJqcHRpNWNycWc4ZyJ9.rePy-8qbz_XFJUo_t4bKTQ",
  },
  version: "0.8.4",
  // Dashboard refresh time in milliseconds
  dashboardRefreshTime: 60 * 1000,
};
