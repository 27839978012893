import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginRequest } from "../domains/login-request";
import { LoginResponse } from "../domains/login-response";
import {
  IUserDashboardColumnsConfig,
  IUserAteColumnsConfig,
  User,
} from "../domains/user";
import { Role } from "../domains/role";

import { CcpApi } from "../providers/api/ccp-api";
import { Account } from "../domains/account";

import * as moment from "moment-timezone";
import { IParimUpdateStatusPayload } from "../domains/employee";
import {
  ITAoutcome,
  ILog,
  IWarnType,
  IGeolocation,
  ISmsSignInConfirmation,
  ISmsSignInConfirmationResultToEmployee,
  ISmsSignInEarlyConfirmation,
  ISmsSignInEarlyConfirmationResultToEmployee,
  ISmsSignOutLateToEmployee,
  ISmsSignOutEarlyConfirmationResultToEmployee,
  ISmsSignOutLateConfirmation,
  ISmsSignOutEarlyConfirmation,
  IManagerAskedToStayLate,
  ISmsSignOutConfirmation,
  ISmsSignOutConfirmationResultToEmployee,
} from "../domains/iYa-bot-schema";
import { IPeriodTimestamps } from "../domains/time";
import { Note } from "../domains/note";
import { UserVenue, IVenue } from "../domains/venue";
import { LocationTime } from "../domains/locationTime";
import { HttpHeaders } from "@angular/common/http";
import { IOverwriteParimDataBody } from "../domains/overwriteShift";

@Injectable({
  providedIn: "root",
})
export class CcpService {
  constructor(public api: CcpApi) {}

  // System
  getSettings(): Observable<any> {
    return this.api.http.get<any>(this.api.baseUrl + `/system/settings`);
  }

  getRolesMap(): Observable<any> {
    return this.api.http.get<any>(
      this.api.baseUrl + `/system/settings/rolesMap`
    );
  }

  getLocationsTime(): Observable<LocationTime[]> {
    return this.api.http.get<LocationTime[]>(
      this.api.baseUrl + `/system/settings/locationsTime`
    );
  }

  updateLocationTime(location: LocationTime): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/system/settings/locationTime`,
      location
    );
  }

  updateStartDate(data: any): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/system/settings/startDate`,
      data
    );
  }

  updateEndDate(data: any): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/system/settings/endDate`,
      data
    );
  }

  getLastCronExecutionTimestamp(): Observable<number> {
    return this.api.http.get<any>(
      this.api.baseUrl + `/system/getLastCronExecutionTimestamp`
    );
  }
  //

  // Login
  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.api.http.post<LoginResponse>(
      this.api.baseUrl + "/users/login",
      loginRequest
    );
  }
  //

  // Logout
  logout(): Promise<void> {
    return this.api.http
      .post<void>(this.api.baseUrl + "/users/logout", null)
      .toPromise();
  }
  //

  // Parim Data
  getParimDataForCoordinator(
    fromDate: Date,
    accountIds: string,
    rangeType: string,
    isCheckingForNewRecords: boolean
  ): Observable<any[]> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let period: IPeriodTimestamps = this.getRange(fromDate, new Date());
    if (rangeType === "single_day") {
      period = this.getRange(fromDate, fromDate);
    }
    return isCheckingForNewRecords
      ? this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/checkingForNewRecords/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/1?timezone=${timeZone}`
        )
      : this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/getParimDataForDashboard/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/1?timezone=${timeZone}`
          // `/parimApi/getParimDataForDashboard/2/1696737600/1696824000/1`
        );
  }

  getParimDataForAuditor(
    fromDate: Date,
    accountIds: string,
    rangeType: string,
    isCheckingForNewRecords: boolean
  ): Observable<any[]> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let period: IPeriodTimestamps = this.getRange(fromDate, new Date());
    if (rangeType === "single_day") {
      period = this.getRange(fromDate, fromDate);
    }
    return isCheckingForNewRecords
      ? this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/checkingForNewRecords/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/2?timezone=${timeZone}`
        )
      : this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/getParimDataForDashboard/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/2?timezone=${timeZone}`
          // `/parimApi/getParimDataForDashboard/2/1696737600/1696824000/2`
        );
  }

  getParimDataForAdjuster(
    fromDate: Date,
    accountIds: string,
    rangeType: string,
    isCheckingForNewRecords: boolean
  ): Observable<any[]> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let period: IPeriodTimestamps = this.getRange(fromDate, new Date());
    if (rangeType === "single_day") {
      period = this.getRange(fromDate, fromDate);
    }
    return isCheckingForNewRecords
      ? this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/checkingForNewRecords/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/4?timezone=${timeZone}`
        )
      : this.api.http.get<any[]>(
          this.api.baseUrl +
            `/parimApi/getParimDataForDashboard/${accountIds}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}/4?timezone=${timeZone}`
          // `/parimApi/getParimDataForDashboard/2/1696737600/1696824000/2`
        );
  }

  updateParimData(
    parimUpdateStatusPayload: IParimUpdateStatusPayload
  ): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/parimApi`,
      parimUpdateStatusPayload
    );
  }

  addNote(note: Note): Observable<void> {
    return this.api.http.post<void>(this.api.baseUrl + `/notes`, note);
  }

  addNoteFile(fileToUpload: any, noteId: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("noteId", noteId.toString());
    formData.append("fileName", fileToUpload.name);
    //let headers = new HttpHeaders();

    return this.api.http.post<void>(
      this.api.baseUrl + `/notes/noteFile`,
      formData
      //{ headers: headers }
    );
  }

  getEmployeeDetails(account_id: number, user_uuid: string): Observable<any> {
    return this.api.http.get<any>(
      `${this.api.baseUrl}/parimApi/getEmployeeInfo/${account_id}/${user_uuid}`
    );
  }

  getIYaBotChatHistory(parimDataId: number): Observable<any> {
    return this.api.http.get<any>(
      `${this.api.baseUrl}/parimApi/getIYaBotChatHistory/${parimDataId}`
    );
  }

  getEntryDetails(parimDataStateId: number): Observable<any> {
    //const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.api.http.get<any>(
      this.api.baseUrl + `/parimApi/getEntryDetails/${parimDataStateId}`
    );
  }
  //

  // Accounts
  getAllAccounts(): Observable<Account[]> {
    return this.api.http.get<Account[]>(this.api.baseUrl + "/accounts");
  }
  //

  // Users
  getAllUsers(): Observable<User[]> {
    return this.api.http.get<User[]>(this.api.baseUrl + "/users");
  }

  createUser(user: User): Observable<void> {
    return this.api.http.post<void>(this.api.baseUrl + `/users`, user);
  }

  updateUser(user: User): Observable<void> {
    return this.api.http.put<void>(this.api.baseUrl + `/users`, user);
  }

  updateUserDashboardColumnsConfig(
    user: IUserDashboardColumnsConfig
  ): Observable<void> {
    return this.api.http.put<void>(this.api.baseUrl + `/users`, user);
  }

  updateUserAteColumnsConfig(user: IUserAteColumnsConfig): Observable<void> {
    return this.api.http.put<void>(this.api.baseUrl + `/users`, user);
  }

  deleteUser(id: number): Observable<any> {
    return this.api.http.delete<any>(this.api.baseUrl + `/users/${id}`);
  }
  //

  // Roles
  getAllRoles(): Observable<Role[]> {
    return this.api.http.get<Role[]>(this.api.baseUrl + "/roles");
  }

  createRole(role: Role): Observable<void> {
    return this.api.http.post<void>(this.api.baseUrl + `/roles`, role);
  }

  updateRole(role: Role): Observable<void> {
    return this.api.http.put<void>(this.api.baseUrl + `/roles`, role);
  }

  deleteRole(id: number): Observable<any> {
    return this.api.http.delete<any>(this.api.baseUrl + `/roles/${id}`);
  }
  //

  // Venues
  getAllVenues(accountId: number): Observable<IVenue[]> {
    return this.api.http.get<IVenue[]>(
      `${this.api.baseUrl}/venues/getAll/${accountId}`
    );
  }

  updateVenues(userVenues: UserVenue): Observable<void> {
    return this.api.http.post<void>(
      this.api.baseUrl + `/users/updateVenues`,
      userVenues
    );
  }
  //

  // Reports
  getDataForReportByLocation(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/byLocation/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportByEmployee(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/byEmployee/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportByCategory(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ) {
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any>(
      this.api.baseUrl +
        `/reports/byCategory/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportAllTimeEntries(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForReportAllTimeEntries");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/allTimeEntries/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportForCoordinator(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForReportForCoordinator");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/forCoordinator/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportAnomaly(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForReportAnomaly");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/anomaly/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForCarrawayReport(
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForCarrawayReport");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/carrawayReport/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForReportAllTimeEntriesByEmloyee(
    accountID: number,
    employeeID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForReportAllTimeEntriesByEmployee");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/allTimeEntriesByEmployee/${accountID}/${employeeID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  getDataForPayRollReport(
    accountID: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForPayRollReport");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/payRoll/${accountID}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  overwriteShift(body: IOverwriteParimDataBody): Observable<any> {
    return this.api.http.put<any[]>(
      this.api.baseUrl + `/parimApi/overwriteParimData`,
      body
    );
  }

  getDataForReportSBTC(
    accountIDs: string,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Observable<any[]> {
    console.log("getDataForReportSBTC");
    const period = this.getRange(dateFrom, dateTo);
    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/reports/sbtcReport/${accountIDs}/${period.startOfDayTimestamp}/${period.endOfDayTimestamp}`
    );
  }

  //

  // Incoming shifts
  getIncomingShifts(accountIds: string): Observable<any[]> {
    console.log("getIncomingShifts");
    // period for the next 24 hours
    const now = moment();
    const startTimestamp = parseInt((now.valueOf() / 1000).toFixed());
    const endTimestamp = startTimestamp + 86400;

    return this.api.http.get<any[]>(
      this.api.baseUrl +
        `/parimApi/getIncomingShifts/${accountIds}/${startTimestamp}/${endTimestamp}`
      // `/parimApi/getParimDataForDashboard/2/1696737600/1696824000/1`
    );
  }

  //

  // iYa bot
  async checkIYaBotLink(type: string, parimDataId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.http
        .get<boolean>(
          this.api.baseUrl + `/iYa/checkIYaBotLink/${type}/${parimDataId}`
        )
        .subscribe({
          next: async (response) => {
            console.log("checkIYaBotLink success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("checkIYaBotLink error", error);
            reject(error);
          },
        });
    });
  }

  async updateSignInPromise(data: ILog): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/updateClockInPromise`, data)
        .subscribe({
          next: async (response) => {
            console.log("updateSignInPromise success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("updateSignInPromise error", error);
            reject(error);
          },
        });
    });
  }

  async logAction(data: ILog): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http.put<void>(this.api.baseUrl + `/iYa/log`, data).subscribe({
        next: async (response) => {
          console.log("logAction success", response);

          resolve(response);
        },
        error: (error) => {
          console.log("logAction error", error);
          reject(error);
        },
      });
    });
  }

  async canNotMakeShift(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/canNotMakeShift/${id}`, {})
        .subscribe({
          next: async (response) => {
            console.log("canNotMakeShift success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("canNotMakeShift error", error);
            reject(error);
          },
        });
    });
  }

  async setTAoutcome(data: ITAoutcome): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/setTAoutcome`, data)
        .subscribe({
          next: async (response) => {
            console.log("SetTAoutcome success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("SetTAoutcome error", error);
            reject(error);
          },
        });
    });
  }

  async updateWarnType(data: IWarnType): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/updateWarnType`, data)
        .subscribe({
          next: async (response) => {
            console.log("updateWarnType success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("updateWarnType error", error);
            reject(error);
          },
        });
    });
  }

  async saveGeolocation(data: IGeolocation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/saveGeolocation`, data)
        .subscribe({
          next: async (response) => {
            console.log("saveGeolocation success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("saveGeolocation error", error);
            reject(error);
          },
        });
    });
  }

  async getEmployeeManagers(parimDataId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .get<any[]>(
          this.api.baseUrl + `/parimApi/getEmployeeManagers/${parimDataId}`
        )
        .subscribe({
          next: async (response) => {
            console.log("getEmployeeManagers success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("getEmployeeManagers error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignInConfirmationSms(data: ISmsSignInConfirmation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/sendSignInConfirmationSms`, data)
        .subscribe({
          next: async (response) => {
            console.log("sendSignInConfirmationSms success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignInConfirmationSms error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignInEarlyConfirmationSms(
    data: ISmsSignInEarlyConfirmation
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/sendSignInEarlyConfirmationSms`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log("sendSignInEarlyConfirmationSms success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignInEarlyConfirmationSms error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignInConfirmationSmsResultToEmployee(
    data: ISmsSignInConfirmationResultToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/sendSignInConfirmationSmsResultToEmployee`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignInConfirmationSmsResultToEmployee success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignInConfirmationSmsResultToEmployee error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async sendSignOutConfirmationSmsResultToEmployee(
    data: ISmsSignOutConfirmationResultToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/sendSignOutConfirmationSmsResultToEmployee`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignOutConfirmationSmsResultToEmployee success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignOutConfirmationSmsResultToEmployee error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async sendSignInEarlyConfirmationSmsResultToEmployee(
    data: ISmsSignInEarlyConfirmationResultToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl +
            `/iYa/sendSignInEarlyConfirmationSmsResultToEmployee`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignInEarlyConfirmationSmsResultToEmployee success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignInEarlyConfirmationSmsResultToEmployee error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async sendSignOutEarlyConfirmationSms(
    data: ISmsSignOutEarlyConfirmation
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/sendSignOutEarlyConfirmationSms`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log("sendSignOutEarlyConfirmationSms success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignOutEarlyConfirmationSms error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignOutEarlyConfirmationSmsResultToEmployee(
    data: ISmsSignOutEarlyConfirmationResultToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl +
            `/iYa/sendSignOutEarlyConfirmationSmsResultToEmployee`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignOutEarlyConfirmationSmsResultToEmployee success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignOutEarlyConfirmationSmsResultToEmployee error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async sendSignOutAccidentallySMSToEmployeeIntroductory(
    parimDataId: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl +
            `/iYa/sendSignOutAccidentallySMSToEmployeeIntroductory/${parimDataId}`,
          {}
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignOutAccidentallySMSToEmployeeIntroductory success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignOutAccidentallySMSToEmployeeIntroductory error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async sendSignOutLateSmsToEmployee(
    data: ISmsSignOutLateToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/sendSignOutLateSmsToEmployee`, data)
        .subscribe({
          next: async (response) => {
            console.log("sendSignOutLateSmsToEmployee success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignOutLateSmsToEmployee error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignOutLateConfirmationSms(
    data: ISmsSignOutLateConfirmation
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/sendSignOutLateConfirmationSms`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log("sendSignOutLateConfirmationSms success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignOutLateConfirmationSms error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignOutLateConfirmationSmsResultToEmployee(
    data: ISmsSignOutEarlyConfirmationResultToEmployee
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl +
            `/iYa/sendSignOutLateConfirmationSmsResultToEmployee`,
          data
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "sendSignOutLateConfirmationSmsResultToEmployee success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log(
              "sendSignOutLateConfirmationSmsResultToEmployee error",
              error
            );
            reject(error);
          },
        });
    });
  }

  async managerAskedToStayLate(data: IManagerAskedToStayLate): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/managerAskedToStayLate`, data)
        .subscribe({
          next: async (response) => {
            console.log(
              "managerAskedToStayLate timestamp sent success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log("managerAskedToStayLate timestamp sent error", error);
            reject(error);
          },
        });
    });
  }

  async sendSignOutConfirmationSms(
    data: ISmsSignOutConfirmation
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(this.api.baseUrl + `/iYa/sendSignOutConfirmationSms`, data)
        .subscribe({
          next: async (response) => {
            console.log("sendSignOutConfirmationSms success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("sendSignOutConfirmationSms error", error);
            reject(error);
          },
        });
    });
  }

  logActionOld(data: ILog): Observable<void> {
    return this.api.http.put<void>(this.api.baseUrl + `/iYa/log`, data);
  }

  SetTAoutcomeOld(data: ITAoutcome): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/iYa/setTAoutcome`,
      data
    );
  }

  updateSignInPromiseOld(data: ILog): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/iYa/updateClockInPromise`,
      data
    );
  }

  canNotMakeShiftOld(id: number): Observable<void> {
    return this.api.http.put<void>(
      this.api.baseUrl + `/iYa/canNotMakeShift/${id}`,
      {}
    );
  }

  async setSOEAccidentallyClockedOutSMSOpen(parimDataId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl +
            `/iYa/setSOEAccidentallyClockedOutSMSOpen/${parimDataId}`,
          {}
        )
        .subscribe({
          next: async (response) => {
            console.log(
              "setSOEAccidentallyClockedOutSMSOpen success",
              response
            );
            resolve(response);
          },
          error: (error) => {
            console.log("setSOEAccidentallyClockedOutSMSOpen error", error);
            reject(error);
          },
        });
    });
  }

  async setIsAccidentallyClockedOut(parimDataId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.http
        .put<void>(
          this.api.baseUrl + `/iYa/setIsAccidentallyClockedOut/${parimDataId}`,
          {}
        )
        .subscribe({
          next: async (response) => {
            console.log("setIsAccidentallyClockedOut success", response);
            resolve(response);
          },
          error: (error) => {
            console.log("setIsAccidentallyClockedOut error", error);
            reject(error);
          },
        });
    });
  }

  // setSOEAccidentallyClockedOutSMSOpen(id: number): Observable<void> {
  //   return this.api.http.put<void>(
  //     this.api.baseUrl + `/iYa/setSOEAccidentallyClockedOutSMSOpen/${id}`,
  //     {}
  //   );
  // }
  // end iYa bot

  // private getCurrentDayStartAndEnd(timeZone: string) {
  //   const now = moment().tz(timeZone);
  //   const startOfDay = now.startOf("day");
  //   const startOfDayTimestamp = parseInt(
  //     (startOfDay.valueOf() / 1000).toFixed()
  //   );
  //   const endOfDayTimestamp = parseInt((startOfDayTimestamp + 86400).toFixed());

  //   return {
  //     startOfDayTimestamp,
  //     endOfDayTimestamp,
  //   };
  // }

  private getRange(
    dateFrom: Date | undefined,
    dateTo: Date | undefined
    // timeZone: string
  ) {
    console.log("In getRange");
    console.log(dateFrom);
    console.log(dateTo);

    if (dateTo === null) {
      dateTo = dateFrom;
    }
    // const startOfDay = moment(dateFrom).tz(timeZone).startOf("day");
    const startOfDay = moment(dateFrom).startOf("day");
    const startOfDayTimestamp = parseInt(
      (startOfDay.valueOf() / 1000).toFixed()
    );

    //const endOfDay = moment(dateTo).tz(timeZone).endOf("day");
    const endOfDay = moment(dateTo).endOf("day");

    let endOfDayTimestamp = parseInt((endOfDay.valueOf() / 1000).toFixed());

    if (startOfDayTimestamp === endOfDayTimestamp) {
      endOfDayTimestamp = startOfDayTimestamp + 86400;
    }

    console.log("In America/Toronto timezone");
    console.log(startOfDayTimestamp);
    console.log(
      moment(startOfDayTimestamp * 1000)
        .tz("America/Toronto")
        .format("M/DD/YYYY HH:mm:ss")
    );

    console.log("In America/Toronto timezone");
    console.log(endOfDayTimestamp);
    console.log(
      moment(endOfDayTimestamp * 1000)
        .tz("America/Toronto")
        .format("M/DD/YYYY HH:mm:ss")
    );

    return {
      startOfDayTimestamp,
      endOfDayTimestamp,
    };
  }
}
