import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { environment } from "../../../environments/environment";
import { CcpApi } from "../../providers/api/ccp-api";

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private api: CcpApi) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!error.url?.includes("/errors")) {
          const errorToLog: IErrorAdd = {
            message: error.message + " | " + error.error.message,
            status: error.status.toString(),
            type: "WCP_UI",
            name: error.name,
          };

          this.api.http
            .put<void>(this.api.baseUrl + `/errors`, errorToLog)
            .subscribe({
              next: async (response) => {},
              error: async (error) => {},
            });
        }

        if (error.status === 401) {
          const currentUrl = window.location.href;
          localStorage.setItem("targetUrl", currentUrl);

          if (!currentUrl.endsWith("/login")) {
            this.auth.resetAll();
            window.location.href = "/login";
          }
        }

        return throwError(() => error);
      })
    );
  }
}

interface IErrorAdd {
  status?: string;
  type?: string;
  name?: string;
  message?: string;
  userId?: string;
}
