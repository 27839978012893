<ion-split-pane [when]="showContent" contentId="main-content">
  <ion-menu contentId="main-content">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title style="font-size: 1rem">
          {{ currentUser ? currentUser.user.role.name : "" }}</ion-title
        >
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <button
        *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
        pButton
        pRipple
        label="DASHBOARD"
        icon="pi pi-list"
        [class]="
          (currentUser &&
            currentUser.user.role.name === 'SUPERUSER' &&
            activeMenuItem === 'dashboard' &&
            !showDashboardSubmenu) ||
          activeMenuItem === 'dashboard' ||
          (!showDashboardSubmenu &&
            (activeMenuItem === 'auditorDashboard' ||
              activeMenuItem === 'coordinatorDashboard'))
            ? 'w-full p-button-sm mb-2 menu-button text-left pl-2 pr-3'
            : 'p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3'
        "
        (click)="showDashboard()"
      ></button>

      <ion-menu-toggle
        autoHide="false"
        *ngIf="
          currentUser &&
          (currentUser.user.role.name === 'COORDINATOR' ||
            currentUser.user.role.name === 'AUDITOR' ||
            currentUser.user.role.name === 'ADJUSTER')
        "
      >
        <button
          pButton
          pRipple
          label="DASHBOARD"
          icon="pi pi-align-left"
          [class]="
            activeMenuItem === 'dashboard'
              ? 'p-button-sm mb-2 menu-button text-left pl-2 pr-3'
              : 'p-button-outlined p-button-sm mb-2 menu-button text-left pl-2 pr-3'
          "
          style="padding-right: 0.5rem; width: 100%"
          (click)="showDashboard()"
        ></button>
      </ion-menu-toggle>

      <ion-menu-toggle
        autoHide="false"
        *ngIf="currentUser && currentUser.user.role.name === 'COORDINATOR'"
      >
        <button
          pButton
          pRipple
          label="INCOMING SHIFTS"
          icon="pi pi-align-left"
          [class]="
            activeMenuItem === 'incomingShifts'
              ? 'p-button-sm mb-2 menu-button text-left pl-2 pr-3'
              : 'p-button-outlined p-button-sm mb-2 menu-button text-left pl-2 pr-3'
          "
          style="padding-right: 0.5rem; width: 100%"
          (click)="incomingShifts()"
        ></button>
      </ion-menu-toggle>

      <ion-menu-toggle autoHide="false">
        <div
          class="mr-2 mb-2"
          *ngIf="
            currentUser &&
            currentUser.user.role.name === 'SUPERUSER' &&
            showDashboardSubmenu
          "
        >
          <button
            pButton
            pRipple
            label="COORDINATOR"
            icon="pi pi-align-left"
            [class]="
              activeMenuItem === 'coordinatorDashboard'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="coordinatorDashboard()"
          ></button>

          <button
            pButton
            pRipple
            label="AUDITOR"
            icon="pi pi-align-left"
            [class]="
              activeMenuItem === 'auditorDashboard'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="auditorDashboard()"
          ></button>

          <button
            pButton
            pRipple
            label="ADJUSTER"
            icon="pi pi-align-left"
            [class]="
              activeMenuItem === 'adjusterDashboard'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="adjusterDashboard()"
          ></button>

          <button
            pButton
            pRipple
            label="INCOMING SHIFTS"
            icon="pi pi-align-left"
            [class]="
              activeMenuItem === 'incomingShifts'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="font-size: 0.7rem; padding-right: 0.5rem; width: 100%"
            (click)="incomingShifts()"
          ></button>
        </div>
      </ion-menu-toggle>

      <!-- <button
        pButton
        pRipple
        label="RED ENTRIES"
        icon="pi pi-user"
        [class]="
          activeMenuItem === 'redEntries'
            ? 'w-full mb-2 menu-button'
            : 'p-button-outlined w-full mb-2 menu-button'
        "
        (click)="redEntries()"
      ></button>

      <button
        pButton
        pRipple
        label="ALL ENTRIES"
        icon="pi pi-user"
        [class]="
          activeMenuItem === 'allEntries'
            ? 'w-full mb-2 menu-button'
            : 'p-button-outlined w-full mb-2 menu-button'
        "
        (click)="allEntries()"
      ></button> -->

      <button
        *ngIf="
          currentUser &&
          (currentUser.user.role.name === 'SUPERUSER' ||
            currentUser.user.role.name === 'COORDINATOR')
        "
        pButton
        pRipple
        label="REPORTS"
        icon="pi pi-chart-bar"
        [class]="
          (activeMenuItem === 'reports' && !showReportSubmenu) ||
          activeMenuItem === 'reports' ||
          (!showReportSubmenu &&
            (activeMenuItem === 'reportByCategory' ||
              activeMenuItem === 'reportByLocation' ||
              activeMenuItem === 'reportByEmployee'))
            ? 'w-full p-button-sm mb-2 menu-button text-left pl-2 pr-3'
            : 'p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3'
        "
        (click)="reports()"
      ></button>

      <ion-menu-toggle autoHide="false">
        <div
          class="mr-2 mb-2"
          *ngIf="
            currentUser &&
            (currentUser.user.role.name === 'SUPERUSER' ||
              currentUser.user.role.name === 'COORDINATOR') &&
            showReportSubmenu
          "
        >
          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="BY CATEGORY"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportByCategory'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportByCategory()"
          ></button>
          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="BY LOCATION"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportByLocation'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportByLocation()"
          ></button>

          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="BY EMPLOYEE"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportByEmployee'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportByEmployee()"
          ></button>

          <button
            pButton
            pRipple
            label="ALL TIME ENTRIES"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportAllTimeEntries'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportAllTimeEntries()"
          ></button>

          <button
            pButton
            pRipple
            label="COORDINATOR REPORT"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportForCoordinator'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportForCoordinator()"
          ></button>

          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="SBTC REPORT"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportSBTC'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportSBTC()"
          ></button>

          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="CARRAWAY REPORT"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportCarraway'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportCarraway()"
          ></button>

          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="ANOMALY REPORT"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'reportAnomaly'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="reportAnomaly()"
          ></button>

          <button
            *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
            pButton
            pRipple
            label="PAYROLL REPORT"
            icon="pi pi-chart-bar"
            [class]="
              activeMenuItem === 'payRollReport'
                ? 'p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
                : 'p-button-outlined p-button-sm mb-2 ml-2 menu-button text-left pl-2 pr-3'
            "
            style="
              font-size: 0.7rem;

              padding-right: 0.5rem;
              width: 100%;
            "
            (click)="payRollReport()"
          ></button>
        </div>
      </ion-menu-toggle>

      <ion-menu-toggle autoHide="false">
        <button
          *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
          pButton
          pRipple
          label="USERS MGT"
          icon="pi pi-users"
          [class]="
            activeMenuItem === 'userManager'
              ? 'w-full p-button-sm mb-2 menu-button text-left pl-2 pr-3'
              : 'p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3'
          "
          (click)="userManager()"
        ></button>

        <button
          *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
          pButton
          pRipple
          label="ROLES MGT"
          icon="pi pi-user"
          [class]="
            activeMenuItem === 'roleManager'
              ? 'w-full p-button-sm mb-2 menu-button text-left pl-2 pr-3'
              : 'p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3'
          "
          (click)="roleManager()"
        ></button>

        <button
          *ngIf="currentUser && currentUser.user.role.name === 'SUPERUSER'"
          pButton
          pRipple
          label="CLIENT TIME"
          icon="pi pi-clock"
          [class]="
            activeMenuItem === 'clientTime'
              ? 'w-full p-button-sm mb-2 menu-button text-left pl-2 pr-3'
              : 'p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3'
          "
          (click)="clientTime()"
        ></button>
      </ion-menu-toggle>

      <button
        pButton
        pRipple
        label="LOGOUT"
        icon="pi pi-sign-out"
        class="p-button-outlined p-button-sm w-full mb-2 menu-button text-left pl-2 pr-3"
        (click)="logout()"
      ></button>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main-content"> </ion-router-outlet>
</ion-split-pane>

<!-- Loading spinner -->
<div *ngIf="loading$ | async" class="cssload-container">
  <div class="cssload-speeding-wheel"></div>
</div>
