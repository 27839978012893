import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: "root",
})
export class CcpApi {
  constructor(public http: HttpClient) {}

  baseUrl = this.getBaseURL();

  getBaseURL(): string {
    return environment.api.server;
  }
}
